<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="licenceCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <div class="section-block mb-4">
              <div class="section-block__title">
                <feather-icon
                  icon="FileTextIcon"
                  width="24"
                  height="24"
                />
                <h2>License details</h2>
              </div>
              <b-form-group
                label="Category"
                label-class="label-class"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Category"
                  vid="category"
                  rules="required"
                >
                  <v-select
                    v-model="form.category"
                    :options="categories"
                    :reduce="category => category.value"
                    label="text"
                    class="selector-height"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="Level"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Level"
                    vid="level"
                    rules="required"
                  >
                    <v-select
                      v-model="form.level"
                      :options="levels"
                      :reduce="level => level.value"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Type"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Type"
                    vid="type"
                    rules="required"
                  >
                    <v-select
                      v-model="form.type"
                      :options="types"
                      :reduce="type => type.value"
                      label="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <b-form-group
                label="Quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Quantity"
                  vid="quantity"
                >
                  <v-select
                    v-model="form.quantity"
                    :options="quantityList"
                    placeholder="Select quantity"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="section-block mb-4">
              <div class="section-block__title">
                <feather-icon
                  icon="UserIcon"
                  width="24"
                  height="24"
                />
                <h2>Owner details</h2>
              </div>
              <div
                class="mb-1"
              >
                <multiselect
                  v-model="selected"
                  :options="options"
                  multiple
                  placeholder="Select owner’s type"
                  class="multiselect"
                  :searchable="false"
                  @select="onSelect"
                />
              </div>

              <b-form-group
                v-if="$can('view', SELECT_CLIENT) && (selected.includes('Client') || client)"
                label="Client"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Client"
                  vid="client_id"
                >
                  <v-select
                    v-model="client"
                    :options="clients"
                    placeholder="Enter name"
                    :class="selected[0] !== 'Client' ? 'disabled-clear owner-select' : 'owner-select'"
                    label="name"
                    @search="onSearch($event, 'client')"
                    @input="onSelectedUser($event, 'client')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="$can('view', SELECT_RESELLER) && (selected.includes('Reseller') || reseller)"
                label="Reseller"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Reseller"
                  vid="reseller_id"
                >
                  <v-select
                    v-model="reseller"
                    :options="resellers"
                    placeholder="Enter name"
                    :class="selected[0] !== 'Reseller' ? 'disabled-clear owner-select' : 'owner-select'"
                    label="name"
                    @search="onSearch($event, 'reseller')"
                    @input="onSelectedUser($event, 'reseller')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="$can('view', SELECT_DISTRIBUTOR) && (selected.includes('Distributor') || distributor)"
                label="Distributor"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Distributor"
                  vid="distributor_id"
                >
                  <v-select
                    v-model="distributor"
                    :options="distributors"
                    placeholder="Enter name"
                    :class="selected[0] !== 'Distributor' ? 'disabled-clear owner-select' : 'owner-select'"
                    label="name"
                    @search="onSearch($event, 'distributor')"
                    @input="onSelectedUser($event, 'distributor')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="$can('view', SELECT_MASTER_DISTRIBUTOR) && (selected.includes('Master Distributor') || masterDistributor)"
                label="Master Distributor"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Master Distributor"
                  vid="master_distributor_id"
                >
                  <v-select
                    v-model="masterDistributor"
                    :options="masterDistributors"
                    placeholder="Enter name"
                    :class="selected[0] !== 'Master Distributor' ? 'disabled-clear owner-select' : 'owner-select'"
                    label="name"
                    @search="onSearch($event, 'master-distributor')"
                    @input="onSelectedUser($event, 'master-distributor')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
          <!-- submit button -->
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="submit-form-button"
              @click.prevent="validationForm"
            >
              <div
                v-if="isFormSubmitting"
              >
                <b-spinner
                  label="Spinning"
                  small
                />
              </div>
              {{ isFormSubmitting ? '' : 'Submit' }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup, BForm, BRow, BCol, BButton, BCard, BSpinner,
} from 'bootstrap-vue'
import {
  required, max, alphaNum, numeric, min,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import rolesPermission from '@/libs/acl_roles/rolesPermission'
import { mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { debounce } from '@/utils/utils'
import Multiselect from 'vue-multiselect'

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    Multiselect,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      form: {
        category: null,
        type: null,
        level: null,
        quantity: null,
      },
      quantityList: [],
      required,
      min,
      max,
      alphaNum,
      numeric,
      options: ['Client', 'Reseller', 'Distributor', 'Master Distributor'],
      selected: [],
      isFormSubmitting: false,

      SELECT_MASTER_DISTRIBUTOR: rolesPermission.SELECT_MASTER_DISTRIBUTOR,
      SELECT_DISTRIBUTOR: rolesPermission.SELECT_DISTRIBUTOR,
      SELECT_RESELLER: rolesPermission.SELECT_RESELLER,
      SELECT_CLIENT: rolesPermission.SELECT_CLIENT,
    }
  },
  computed: {
    ...mapFields('user', [
      'masterDistributor',
      'distributor',
      'reseller',
      'client',
      'masterDistributorList',
      'distributorList',
      'resellerList',
      'clientList',
      'clients',
      'resellers',
      'distributors',
      'masterDistributors',
    ]),
    ...mapFields('licence', [
      'levels',
      'categories',
      'types',
    ]),
  },
  watch: {
    distributor(val) {
      if (!val) {
        this.selected = ['Master Distributor']
        this.client = null
        this.reseller = null
      } else if (!this.reseller && !this.client) {
        this.selected = ['Distributor']
      }
    },
    reseller(val) {
      if (!val) {
        this.selected = ['Distributor']
        this.client = null
      } else if (!this.client) {
        this.selected = ['Reseller']
      }
    },
    masterDistributor(val) {
      if (!val) {
        this.selected = ['Master Distributor']
        this.distributor = null
        this.reseller = null
        this.client = null
      } else if (!this.reseller && !this.distributor && !this.client) {
        this.selected = ['Master Distributor']
      }
    },
    client(val) {
      if (!val) {
        this.selected = ['Reseller']
      } else {
        this.selected = ['Client']
      }
    },
  },
  created() {
    this.getOptions()
    this.search({ query: '', company_type: 'site' }, this.fetchSiteList)
    this.fetchCompaniesList({ query: '', company_type: 'master-distributor' })
    this.fetchCompaniesList({ query: '', company_type: 'distributor' })
    this.fetchCompaniesList({ query: '', company_type: 'reseller' })
    this.fetchCompaniesList({ query: '', company_type: 'client' })
    for (let i = 1; i <= 20; i += 1) {
      this.quantityList.push(i)
    }

    if (this.$can('view', this.SELECT_MASTER_DISTRIBUTOR)) {
      this.options = ['Client', 'Reseller', 'Distributor', 'Master Distributor']
    } else if (this.$can('view', this.SELECT_DISTRIBUTOR)) {
      this.options = ['Client', 'Reseller', 'Distributor']
    } else if (this.$can('view', this.SELECT_RESELLER)) {
      this.options = ['Client', 'Reseller']
    } else if (this.$can('view', this.SELECT_CLIENT)) {
      this.options = ['Client']
    } else {
      this.options = []
    }
  },
  methods: {
    ...mapActions('user', [
      'fetchCompaniesList',
      'fetchLicenceSelectedCompany',
    ]),
    ...mapActions('licence', [
      'createItem',
      'getOptions',
    ]),
    onSelect(option) {
      console.log('option :>> ', option)
      if (option === 'Client') {
        this.selected = ['Client']
      }

      if (option === 'Reseller') {
        this.selected = ['Reseller']
        this.client = null
      }

      if (option === 'Distributor') {
        this.selected = ['Distributor']
        this.reseller = null
        this.client = null
      }

      if (option === 'Master Distributor') {
        this.selected = ['Master Distributor']
        this.distributor = null
        this.reseller = null
        this.client = null
      }
    },
    ...mapMutations({
      resetUserState: 'user/RESET_STATE',
    }),
    onSearch(query, type) {
      if (!query) {
        return
      }

      let payload = {
        query,
        company_type: type,
      }
      if (type === 'master-distributor') {
        this.search(payload, this.fetchCompaniesList)
      }

      if (type === 'distributor') {
        this.search(payload, this.fetchCompaniesList)
      }

      if (type === 'reseller') {
        if (this.distributor) {
          payload = {
            ...payload,
            ...{ distributor_id: this.distributor.id },
          }
        }

        this.search(payload, this.fetchCompaniesList)
      }

      if (type === 'client') {
        if (this.distributor) {
          payload = {
            ...payload,
            ...{ distributor_id: this.distributor.id },
          }
        }

        if (this.reseller) {
          payload = {
            ...payload,
            ...{ reseller_id: this.reseller.id },
          }
        }

        this.search(payload, this.fetchCompaniesList)
      }
    },
    search: debounce((payload, fetchAction) => {
      fetchAction({ ...payload })
    }, 350),
    async onSelectedUser(option, type) {
      let payload = {}
      payload.company_id = option.id
      if (type === 'master-distributor') {
        this.distributor = null
        this.reseller = null
        this.client = null
        this.distributorList = []
        this.resellerList = []
        this.clientList = []
        this.site = null
      }

      if (type === 'distributor') {
        this.reseller = null
        this.client = null
        this.resellerList = []
        this.clientList = []
        this.site = null
        this.selected = ['Distributor']
        if (!option) {
          return
        }
        this.fetchLicenceSelectedCompany(payload)
      }

      if (type === 'reseller') {
        this.client = null
        this.site = null

        this.clientList = []
        this.selected = ['Reseller']
        if (!option) {
          return
        }
        this.fetchLicenceSelectedCompany(payload)
      }

      if (type === 'client') {
        this.site = null
        if (!option) {
          return
        }
        this.selected = ['Client']
        if (!this.reseller && this.distributor) {
          payload = {
            ...payload,
            ...{ distributor_id: this.distributor.id },
          }
        }

        payload = {
          ...payload,
        }

        this.fetchLicenceSelectedCompany(payload)
      }
    },
    async validationForm() {
      const valid = await this.$refs.licenceCreate.validate()

      if (valid) {
        try {
          let payload = {
            ...this.form,
          }

          if (this.$can('view', this.SELECT_MASTER_DISTRIBUTOR)) {
            payload = {
              ...payload,
              ...{ master_distributor_id: this.masterDistributor ? this.masterDistributor.id : null },
            }
          }

          if (this.$can('view', this.SELECT_DISTRIBUTOR)) {
            payload = {
              ...payload,
              ...{ distributor_id: this.distributor ? this.distributor.id : null },
            }
          }

          if (this.$can('view', this.SELECT_RESELLER)) {
            payload = {
              ...payload,
              ...{ reseller_id: this.reseller ? this.reseller.id : null },
            }
          }

          if (this.$can('view', this.SELECT_CLIENT)) {
            payload = {
              ...payload,
              ...{ client_id: this.client ? this.client.id : null },
            }
          }
          payload = {
            ...payload,
          }

          this.isFormSubmitting = true
          await this.createItem(payload)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'licence-list' })
        } catch (error) {
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          if (status === 422) {
            const { fields } = error.response.data

            this.$refs.licenceCreate.setErrors(fields)
          }
        } finally {
          this.isFormSubmitting = false
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.resetUserState()

    next()
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
