var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"licenceCreate"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"FileTextIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("License details")])],1),_c('b-form-group',{attrs:{"label":"Category","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Category","vid":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"selector-height",attrs:{"options":_vm.categories,"reduce":function (category) { return category.value; },"label":"text"},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"neighbouring-form-container"},[_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Level","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Level","vid":"level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.levels,"reduce":function (level) { return level.value; },"label":"text"},model:{value:(_vm.form.level),callback:function ($$v) {_vm.$set(_vm.form, "level", $$v)},expression:"form.level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"neighbouring-form-group",attrs:{"label":"Type","label-class":"label-class"}},[_c('validation-provider',{attrs:{"name":"Type","vid":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.types,"reduce":function (type) { return type.value; },"label":"text"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-form-group',{attrs:{"label":"Quantity"}},[_c('validation-provider',{attrs:{"name":"Quantity","vid":"quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.quantityList,"placeholder":"Select quantity"},model:{value:(_vm.form.quantity),callback:function ($$v) {_vm.$set(_vm.form, "quantity", $$v)},expression:"form.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"section-block mb-4"},[_c('div',{staticClass:"section-block__title"},[_c('feather-icon',{attrs:{"icon":"UserIcon","width":"24","height":"24"}}),_c('h2',[_vm._v("Owner details")])],1),_c('div',{staticClass:"mb-1"},[_c('multiselect',{staticClass:"multiselect",attrs:{"options":_vm.options,"multiple":"","placeholder":"Select owner’s type","searchable":false},on:{"select":_vm.onSelect},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),(_vm.$can('view', _vm.SELECT_CLIENT) && (_vm.selected.includes('Client') || _vm.client))?_c('b-form-group',{attrs:{"label":"Client"}},[_c('validation-provider',{attrs:{"name":"Client","vid":"client_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:_vm.selected[0] !== 'Client' ? 'disabled-clear owner-select' : 'owner-select',attrs:{"options":_vm.clients,"placeholder":"Enter name","label":"name"},on:{"search":function($event){return _vm.onSearch($event, 'client')},"input":function($event){return _vm.onSelectedUser($event, 'client')}},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3371542883)})],1):_vm._e(),(_vm.$can('view', _vm.SELECT_RESELLER) && (_vm.selected.includes('Reseller') || _vm.reseller))?_c('b-form-group',{attrs:{"label":"Reseller"}},[_c('validation-provider',{attrs:{"name":"Reseller","vid":"reseller_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:_vm.selected[0] !== 'Reseller' ? 'disabled-clear owner-select' : 'owner-select',attrs:{"options":_vm.resellers,"placeholder":"Enter name","label":"name"},on:{"search":function($event){return _vm.onSearch($event, 'reseller')},"input":function($event){return _vm.onSelectedUser($event, 'reseller')}},model:{value:(_vm.reseller),callback:function ($$v) {_vm.reseller=$$v},expression:"reseller"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3983096524)})],1):_vm._e(),(_vm.$can('view', _vm.SELECT_DISTRIBUTOR) && (_vm.selected.includes('Distributor') || _vm.distributor))?_c('b-form-group',{attrs:{"label":"Distributor"}},[_c('validation-provider',{attrs:{"name":"Distributor","vid":"distributor_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:_vm.selected[0] !== 'Distributor' ? 'disabled-clear owner-select' : 'owner-select',attrs:{"options":_vm.distributors,"placeholder":"Enter name","label":"name"},on:{"search":function($event){return _vm.onSearch($event, 'distributor')},"input":function($event){return _vm.onSelectedUser($event, 'distributor')}},model:{value:(_vm.distributor),callback:function ($$v) {_vm.distributor=$$v},expression:"distributor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1775679189)})],1):_vm._e(),(_vm.$can('view', _vm.SELECT_MASTER_DISTRIBUTOR) && (_vm.selected.includes('Master Distributor') || _vm.masterDistributor))?_c('b-form-group',{attrs:{"label":"Master Distributor"}},[_c('validation-provider',{attrs:{"name":"Master Distributor","vid":"master_distributor_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:_vm.selected[0] !== 'Master Distributor' ? 'disabled-clear owner-select' : 'owner-select',attrs:{"options":_vm.masterDistributors,"placeholder":"Enter name","label":"name"},on:{"search":function($event){return _vm.onSearch($event, 'master-distributor')},"input":function($event){return _vm.onSelectedUser($event, 'master-distributor')}},model:{value:(_vm.masterDistributor),callback:function ($$v) {_vm.masterDistributor=$$v},expression:"masterDistributor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1988688585)})],1):_vm._e()],1)]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"submit-form-button",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.isFormSubmitting)?_c('div',[_c('b-spinner',{attrs:{"label":"Spinning","small":""}})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.isFormSubmitting ? '' : 'Submit')+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }